/** ===== **/
/** buttons **/
/** ===== **/
@use "./../../00-base/variables/" as *;
@use "./../../00-base/functions/" as *;
@use "./../../00-base/grid/_grid.mixins" as grid-mixins;
@use "./button-core.mixins" as button-core;
@use "./button-variants.mixins" as button-variants;
@use "./button-themes.mixins" as button-themes;
@use "./button.variables" as button-variables;
/** ===== **/
.#{$namespace}-button {
  @include button-core.core;
  @include button-variants.button-variant(button-variables.$button--default);
  @include button-themes.button-theme(button-variables.$button--default);
}
.#{$namespace}-button--azure {
  @include button-variants.button-variant(button-variables.$button--azure);
  @include button-themes.button-theme(button-variables.$button--azure);
}
.#{$namespace}-button--inverted-azure {
  @include button-variants.button-variant(
    button-variables.$button--inverted-azure
  );
  @include button-themes.button-theme(button-variables.$button--inverted-azure);
}

.#{$namespace}-button--icon-only {
  @include button-variants.button-variant--icon-only(
    button-variables.$button--icon-only
  );
  @include button-themes.button-theme--icon-only(
    button-variables.$button--icon-only
  );
}
