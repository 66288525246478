/** ===== **/
/** input theme mixins **/
/** ===== **/
@use "sass:map";
/** ===== **/
@mixin input-theme($variables, $block: null) {
  @if $block == null {
    $block: "&";
  }
  background-color: map.get($variables, "bg-color");
  border-color: map.get($variables, "border-color");
  border-style: map.get($variables, "border-style");
  color: map.get($variables, "color");
  &:hover {
    background-color: map.get($variables, "hover--bg-color");
    border-color: map.get($variables, "hover--border-color");
    color: map.get($variables, "hover--color");
  }
  &:focus {
    background-color: map.get($variables, "focus--bg-color");
    border-color: map.get($variables, "focus--border-color");
    color: map.get($variables, "focus--color");
  }
  &:active {
    background-color: map.get($variables, "active--bg-color");
    border-color: map.get($variables, "active--border-color");
    color: map.get($variables, "active--color");
  }
}
