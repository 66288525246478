/** =============== **/
/** Color Variables **/
/** =============== **/
$color-white: #fff !default;
$color-transparent: transparent !default; 
$color-hit-gray: #adb5bd !default;
$color-wild-sand: #f4f4f4 !default;
$color-borders: #eeeeee !default;
$color-alabaster: #f8f8f8 !default;
$color-boulder: #757575 !default;
$color-azure: #0077FF !default;
$color-gallery: #ededed !default;
$color-gray: #909090 !default;