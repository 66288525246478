/** ===== **/
/** button variants mixins **/
/** ===== **/
@use "sass:map";
/** ===== **/

/** ===== **/
/** Default button **/
@mixin button-variant($variables, $block: null) {
  @if $block == null {
    $block: "&";
  }
  align-items: map.get($variables, "align-items");
  border-width: map.get($variables, "border-width");
  cursor: map.get($variables, "cursor");
  display: map.get($variables, "display");
  font-size: map.get($variables, "font-size");
  font-weight: map.get($variables, "font-weight");
  height: map.get($variables, "height");
  line-height: map.get($variables, "line-height");
  padding: map.get($variables, "padding");
  text-align: map.get($variables, "text-align");
  vertical-align: map.get($variables, "vertical-align");
  width: map.get($variables, "width");
}
/** ===== **/

/** ===== **/
/** Icon only button **/
@mixin button-variant--icon-only($variables, $block: null) {
  @if $block == null {
    $block: "&";
  }
  align-items: map.get($variables, "align-items");
  border-width: map.get($variables, "border-width");
  cursor: map.get($variables, "cursor");
  display: map.get($variables, "display");
  font-size: map.get($variables, "font-size");
  font-weight: map.get($variables, "font-weight");
  height: map.get($variables, "height");
  line-height: map.get($variables, "line-height");
  padding: map.get($variables, "padding");
  text-align: map.get($variables, "text-align");
  vertical-align: map.get($variables, "vertical-align");
  width: map.get($variables, "width");
  .digi-button__text {
    display: none;
  }
}
/** ===== **/
